'use client';

import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PiwikProProvider from '@piwikpro/next-piwik-pro';

export default function Providers({ children }) {
  const [queryClient] = React.useState(() => new QueryClient());
  return (
    <>
      {process.env.NODE_ENV !== 'development' && (
        <PiwikProProvider
          containerId="4e6284d9-a21a-4ffc-b50f-ee7b29025b33"
          containerUrl="https://tangibletracks.containers.piwik.pro"
        />
      )}
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}
